/* @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap"); */

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .btn {
    text-transform: none;
  }
}

@layer utilities {
  .circleWidthHeight {
    width: 400px;
    height: 400px;
    border-radius: 50%;
  }
  .circleWidthHeightXs {
    width: 350px;
    height: 350px;
  }
}

@media (max-width: 767px) {
  .circleWidthHeight {
    width: 300px;
    height: 300px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .circleWidthHeight {
    width: 300px;
    height: 300px;
  }
}

@media (min-width: 1024px) {
  .circleWidthHeight {
    width: 400px;
    height: 400px;
  }
}
@font-face {
  font-family: Circular Std;
  src: url(./CircularStd-Book.ttf);
}

:root {
  --black-gradient: linear-gradient(
    144.39deg,
    #ffffff -278.56%,
    #6d6d6d -78.47%,
    #11101d 91.61%
  );
  --card-shadow: 0px 20px 100px -10px rgba(66, 71, 91, 0.1);
  font-family: Circular Std;
}

* {
  scroll-behavior: smooth;
}

.text-gradient {
  background: radial-gradient(
    64.18% 64.18% at 71.16% 35.69%,
    #def9fa 0.89%,
    #bef3f5 17.23%,
    #9dedf0 42.04%,
    #7de7eb 55.12%,
    #5ce1e6 71.54%,
    #33bbcf 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.fym-text-gradient {
  background: linear-gradient(270deg, #742b88 0%, #2b499e 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.fym-text-gradient span {
  color:  #8D7D9C;
  }

.bg-blue-gradient {
  background: linear-gradient(
    157.81deg,
    #def9fa -43.27%,
    #bef3f5 -21.24%,
    #9dedf0 12.19%,
    #7de7eb 29.82%,
    #5ce1e6 51.94%,
    #33bbcf 90.29%
  );
}

.bg-black-gradient {
  background: linear-gradient(
    144.39deg,
    #ffffff -278.56%,
    #6d6d6d -78.47%,
    #11101d 91.61%
  );
}

.bg-black-gradient-2 {
  background: linear-gradient(
    -168.39deg,
    #ffffff -278.56%,
    #6d6d6d -78.47%,
    #11101d 91.61%
  );
}

.bg-gray-gradient {
  background: linear-gradient(
    153.47deg,
    rgba(255, 255, 255, 0) -341.94%,
    #14101d 95.11%
  );
}

.bg-discount-gradient {
  background: linear-gradient(125.17deg, #272727 0%, #11101d 100%);
}

.box-shadow {
  box-shadow: 0px 20px 100px -10px rgba(66, 71, 91, 0.1);
}

.sidebar {
  -webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes slide-top {
  0% {
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.feature-card:hover {
  background: var(--black-gradient);
  box-shadow: var(--card-shadow);
}

.feedback-container .feedback-card:last-child {
  margin-right: 0px;
}

.feedback-card {
  background: transparent;
}

.feedback-card:hover {
  background: var(--black-gradient);
}

.blue__gradient {
  background: linear-gradient(180deg, rgba(188, 165, 255, 0) 0%, #214d76 100%);
  filter: blur(123px);
}

.pink__gradient {
  background: linear-gradient(90deg, #f4c4f3 0%, #fc67fa 100%);
  filter: blur(900px);
}

.white__gradient {
  background: rgba(255, 255, 255, 0.6);
  filter: blur(750px);
}

.buttonCircleSection {
  /* Auto layout */

  /* display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; */
  padding: 16px 32px;
  gap: 8px;

  /* width: 263px;
  height: 48px; */
  color: white;
  background: linear-gradient(
      0deg,
      rgba(116, 43, 136, 0.25),
      rgba(116, 43, 136, 0.25)
    ),
    linear-gradient(300.97deg, #742b88 0%, #2b499e 100%);
  background-blend-mode: color-dodge, normal;
  /* Button/Blue/01 */

  box-shadow: 0px 4px 10px rgba(43, 73, 158, 0.05);
  border-radius: 30px;
}

.knowMoreGradient {
  background: linear-gradient(300.97deg, #742b88 0%, #2b499e 100%);
  border-radius: 40px;
}

.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
  gap: 24px;
  isolation: isolate;
  background: linear-gradient(300.97deg, #742b88 0%, #2b499e 100%);
  /* margin-top: 20px; */
}

.footer.end {
  /* width: 308px; */
  /* height: 28px; */

  /* FYM/Body/Small */

  /* font-family: "Circular Std"; */
  font-style: normal;
  font-weight: 450;
  font-size: 22px;
  /* line-height: 28px; */
  /* identical to box height, or 175% */

  text-align: center;

  /* FYM/Neutral/500 */

  color: #b5aac0;
}

.footerNew {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 120px;
  gap: 24px;
  isolation: isolate;

  /* width: 1920px; */
  height: 252px;

  /* FYM/Primary/Gradient */

  background: linear-gradient(300.97deg, #742b88 0%, #2b499e 100%);
}

.linkForFooter {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;

  width: 60px;
  height: 36px;
}
.navbg {
  background: linear-gradient(
    0deg,
    rgba(248, 244, 249, 1) 0%,
    rgba(125, 76, 155, 1) 61%,
    rgba(114, 108, 175, 1) 100%
  );
}
.learn-more-gradient {
  background: linear-gradient(
    358.87deg,
    #ffffff 1.09%,
    #742b88 50.1%,
    #2b499e 99.11%
  );
}

.containerOfAbout {
  /* background: rgba(116, 43, 136, 0.25); */
  background: #00000054;
  border-radius: 24px;
}
.headTextOfAboutSection {
  /* font-family: 'Circular Std'; */
  font-style: normal;
  font-weight: 900;
  font-size: 48px;
  line-height: 44px;
  text-align: center;
  color: #ffffff;

  
}

.subHeadTextForAboutSection {
  /* width: 1460px;
  height: 46px; */
  /* font-family: 'Circular Std'; */
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: #ffffff;
  
}
.contentOfAbout {
  /* font-family: 'Circular Std'; */
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: #ffffff;
}

.aboutbt-1 {
  padding: 8px 22px;
  /* gap: 8px; */

  /* width: 294px;
  height: 48px; */
  background: #f5f7fc;
  box-shadow: 0px 4px 10px rgba(43, 73, 158, 0.05);
  border-radius: 30px;
  font-size: 16px;
}

.aboutbt-2 {
  padding: 8px 22px;
  border: 2px solid #f5f7fc;
  filter: drop-shadow(0px 4px 10px rgba(43, 73, 158, 0.05));
  border-radius: 30px;
  font-size: 16px;
}

.classForFoundingNav {
  background: linear-gradient(180deg, #2b499e 0%, #742b88 51.04%, #ffffff 100%);
  filter: blur(25px);
}

.feelyoutextContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;

  width: 270px;
  height: 32px;
}

.dash {
  width: 40px;
  height: 4px;

  /* FYM/Primary/Gradient */

  background: linear-gradient(300.97deg, #742b88 0%, #2b499e 100%);
  border-radius: 100px;
}

.feelyoutext {
  /* FYM/Heading/H3 */

  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
  /* or 142% */

  display: flex;
  align-items: center;
  text-align: center;

  /* FYM/Primary/Gradient */

  background: linear-gradient(300.97deg, #742b88 0%, #2b499e 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.dash-g {
  width: 40px;
  height: 4px;

  /* FYM/Primary/Gradient */

  background: #716480;
  border-radius: 100px;
}

.feelyoutext-g {
  /* FYM/Heading/H3 */

  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
  /* or 142% */

  display: flex;
  align-items: center;
  text-align: center;

  /* FYM/Primary/Gradient */

  background: #716480;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}


.foundingHeadText {
  background: linear-gradient(300.97deg, #742b88 0%, #2b499e 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.subHeadTextForFounding {
  color: #716480;
  font-weight: 450;
  font-size: 18px;
}

.thirdSmallHeadTextForFounding {
  font-style: normal;
  font-weight: 450;
  font-size: 18px;
  line-height: 36px;

  background: linear-gradient(300.97deg, #742b88 0%, #2b499e 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.our-journey {
  font-style: normal;
  font-weight: 900;
  font-size: 90px;
  font-style: normal;
  background: linear-gradient(300.97deg, #742b88 0%, #2b499e 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.sheldenTextPara {
  font-family: "Circular Std";
  font-style: normal;
  font-weight: 450;
  font-size: 18px;
  line-height: 28px;
  /* or 164% */

  /* FYM/Neutral/600 */

  color: #8d7d9c;
}
.wefeelYouText {
  font-family: "Circular Std";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 46px;
  /* identical to box height, or 128% */

  /* FYM/Primary/Purple */

  color: #742b88;
}

.founderOfFeelYouMusic {
  font-family: "Circular Std";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
  /* identical to box height, or 142% */

  /* FYM/Neutral/700 */

  color: #716480;
}

.rip {
  display: inline-block !important;
  font-family: "Circular Std";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
  /* identical to box height, or 142% */
  display: flex;
  align-items: center;
  text-align: center;

  /* FYM/Neutral/700 */

  color: #716480;
}

.ankushText {
  /* FYM/Heading/H1 */
  display: inline-block !important;
  font-family: "Circular Std";
  font-style: normal;
  font-weight: 700;
  font-size: 56px;
  line-height: 66px;
  /* identical to box height, or 118% */

  display: flex;
  align-items: center;
  text-align: center;

  /* FYM/Primary/Gradient */

  background: linear-gradient(300.97deg, #742b88 0%, #2b499e 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.timeLine {
  /* FYM/Heading/H3 */
  display: inline-block !important;
  font-family: "Circular Std";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
  /* identical to box height, or 142% */

  display: flex;
  align-items: center;
  text-align: center;

  /* FYM/Neutral/600 */

  color: #8d7d9c;
}

.textInfoForAnkush {
  /* position: absolute; */
  /* width: 1483px; */
  /* height: 249px; */
  /* left: 218.5px; */
  /* top: 862px; */

  /* FYM/Body/Large */

  /* font-family: "Circular Std"; */
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 28px;
  /* or 164% */

  display: flex;
  align-items: center;
  text-align: center;

  /* FYM/Neutral/500 */

  color: #b5aac0;
}

.LearnMore {
  background: linear-gradient(
      358.87deg,
      #ffffff 1.09%,
      #742b88 50.1%,
      #2b499e 99.11%
    ),
    url(./assets/assets/learnmore_gradient.svg);
  background-blend-mode: multiply, normal;

  /* margin-top: 100px; */
}

.headTextForLearnSection {
  font-family: "Circular Std";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  /* or 109% */

  text-align: center;

  /* FYM/Secondary/Light */

  color: #f5f7fc;
}

.player {
  /* Basic/06 */
  /* box-shadow: 0px 32px 60px rgba(40, 34, 41, 0.24); */
  border-radius: 44.537px;
}
.social-icon {
  background-color: #fff;
  border-radius: 30px;
  margin-right: 8px;
}
.bg-navbar-gradient {
  background: linear-gradient(180deg, #2b499e 0%, #742b88 51.04%, #ffffff 100%);
  filter: blur(25px);
}

/* Moema */
.button--moema {
	-webkit-transition: background-color 0.3s, color 0.3s;
	transition: background-color 0.3s, color 0.3s;
}

.button--moema::before {
	content: '';
	position: absolute;
	top: -20px;
	left: -20px;
	bottom: -20px;
	right: -20px;
	background: inherit;
	border-radius: 50px;
	z-index: -1;
	opacity: 0.4;
	-webkit-transform: scale3d(0.8, 0.5, 1);
	transform: scale3d(0.8, 0.5, 1);
}

.button--moema:hover {
	-webkit-transition: background-color 0.1s 0.3s, color 0.1s 0.3s;
	transition: background-color 0.1s 0.3s, color 0.1s 0.3s;
	-webkit-animation: anim-moema-1 0.3s forwards;
	animation: anim-moema-1 0.3s forwards;
}

.button--moema:hover::before {
	-webkit-animation: anim-moema-2 0.3s 0.3s forwards;
	animation: anim-moema-2 0.3s 0.3s forwards;
}
@-webkit-keyframes anim-moema-1 {
	60% {
		-webkit-transform: scale3d(0.8, 0.8, 1);
		transform: scale3d(0.8, 0.8, 1);
	}
	85% {
		-webkit-transform: scale3d(1.1, 1.1, 1);
		transform: scale3d(1.1, 1.1, 1);
	}
	100% {
		-webkit-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1);
	}
}
@keyframes anim-moema-1 {
	60% {
		-webkit-transform: scale3d(0.8, 0.8, 1);
		transform: scale3d(0.8, 0.8, 1);
	}
	85% {
		-webkit-transform: scale3d(1.1, 1.1, 1);
		transform: scale3d(1.1, 1.1, 1);
	}
	100% {
		-webkit-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1);
	}
}
@-webkit-keyframes anim-moema-2 {
	to {
		opacity: 0;
		-webkit-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1);
	}
}
@keyframes anim-moema-2 {
	to {
		opacity: 0;
		-webkit-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1);
	}
}
.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}
.sarah-pic {
  background-color: #D9D9D9;
  padding-top: 82px;
  padding-bottom: 90px;
  border-top-left-radius: 260px;
  border-top-right-radius: 260px;
}

.hoverBtn:hover{
  background: linear-gradient(0deg, rgba(198, 210, 253, 0.25), rgba(198, 210, 253, 0.25)), linear-gradient(300.97deg, #742B88 0%, #2B499E 100%) !important;
  background-blend-mode: lighten, normal !important;
}

.hoverBtnSc:hover{
  background:#fff;
}
